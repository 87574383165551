import React, { useEffect } from 'react';
import { PageHeader } from '@ant-design/pro-components';
import {
  Button,
  Divider,
  Empty,
  Form,
  Input,
  message,
  Row,
  Select,
  Space,
  Table,
  Typography
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { OverseaWarehouse } from '../../../../../custom_types/wms-page';
import { Country, COUNTRY_NAMES } from '../../../../../shared/utils/constants';
import { selectHwcClientWarehouses } from '../../../../../redux/hwc/hwcClientWarehouseSlice';
import {
  HwcFulfillOrderData,
  HwcFulFillProduct
} from '../../../../../custom_types/overseawarehouse/hwc-fulfill';
import {
  createHwcFulfillOrderHandler,
  selectHwcClientCreateOrderLoading,
  selectShowFulfillStockModal,
  setShowFulfillStockModal
} from '../../../../../redux/hwc/hwcClientFulfillOrderSlice';
import { fetchHwcStocksHandler } from '../../../../../redux/hwc/hwcStockSlice';
import HwcFulfillStocksModal from './HwcFulfillStocksModal';

interface HwcFulfillOrderCreatePanelProps {
  hwcClient: OverseaWarehouse;
}

const HwcFulfillOrderCreatePanel = ({ hwcClient }: HwcFulfillOrderCreatePanelProps) => {
  const hwcClientId = hwcClient.id;
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const ckCode = Form.useWatch('ck_nums', form);
  const chqd = Form.useWatch('chqd', form);
  const loading = useSelector(selectHwcClientCreateOrderLoading);
  const hwcClientWarehouseList = useSelector(selectHwcClientWarehouses);
  const showFulfillStockModal = useSelector(selectShowFulfillStockModal);
  const [orderProducts, setOrderProducts] = React.useState<HwcFulFillProduct[]>([]);

  //   useEffect(() => {

  //     dispatch(fetchFulfillStockHandler(hwcClientWarehouseList[0].id));
  //     form.resetFields();
  //   }, [dispatch, hwcClientId, form]);

  const addFulfillStockHandler = () => {
    const warehouse = hwcClientWarehouseList.find((item) => item.code === ckCode);
    if (warehouse) {
      dispatch(fetchHwcStocksHandler(hwcClientId, warehouse.code));
      dispatch(setShowFulfillStockModal(true));
    }
  };

  const closeFulfillStockModal = () => {
    dispatch(setShowFulfillStockModal(false));
  };

  const modalChangeHandler = (products: HwcFulFillProduct[]) => {
    const newList = [...orderProducts];
    const result = newList.concat(products);
    console.log(result);
    setOrderProducts(result);
    dispatch(setShowFulfillStockModal(false));
  };

  const removeProductItem = (index: number) => {
    const newList = [...orderProducts];
    newList.splice(index, 1);
    setOrderProducts(newList);
  };

  const okClickedHandler = () => {
    form
      .validateFields()
      .then((values) => {
        const data: HwcFulfillOrderData = {
          ck_nums: values.ck_nums, // 仓库编码
          deliver_no: '', // 发货单号 orderId
          chqd: values.chqd, // 物流渠道
          country: values.country, // 国家
          state: values.state, // 州
          city: values.city, // 市
          zip: values.zip, // 邮编
          address: values.address, // 地址
          contact: values.contact, // 联系人
          mobile: values.mobile, // 手机号
          phone: values.phone || '', // 电话号码
          email: values.email || '', // 邮箱
          platform: values.platform || '', // 销售平台/出货类型
          remark: values.remark, // 备注
          deliver_products: orderProducts,
          waybill: values.waybill, // 自己提供面单号
          pda_url: values.pda_url // 自己提供面单链接
        };
        if (data.ck_nums && data.deliver_products.length > 0) {
          dispatch(
            createHwcFulfillOrderHandler(
              data,
              hwcClientWarehouseList.find((item) => item.code === data.ck_nums) === undefined
                ? undefined
                : hwcClientWarehouseList.find((item) => item.code === data.ck_nums)!.id,
              hwcClientId,
              form,
              setOrderProducts
            )
          );
        } else {
          message.error('请填写完整信息');
        }
      })
      .catch((error) => {
        // console.log(error.errorFields[0].errors[0]);
        message.error(error.errorFields[0].errors[0]);
      });
  };

  const columns = [
    {
      title: '序号',
      key: 'index',
      render: (_: any, __: HwcFulFillProduct, index: number) => index + 1
    },
    {
      title: 'SKU',
      key: 'sku',
      dataIndex: 'sku'
    },
    {
      title: '发货数量',
      key: 'nums',
      dataIndex: 'nums'
    }
  ];

  return (
    <div style={{ backgroundColor: 'white', padding: '20px' }}>
      {showFulfillStockModal && (
        <HwcFulfillStocksModal onChange={modalChangeHandler} onCancel={closeFulfillStockModal} />
      )}
      <PageHeader title="代发货申请" />
      <Form form={form}>
        <Divider orientation="left">发货申请</Divider>
        <Row>
          <Space size="large">
            <Form.Item
              label="代发仓库"
              name="ck_nums"
              rules={[{ required: true, message: '请选择代发仓库' }]}
              initialValue={hwcClientWarehouseList[0].code}
            >
              <Select
                style={{ width: 280 }}
                options={hwcClientWarehouseList.map((item) => ({
                  label: item.name,
                  value: item.code
                }))}
                //   onChange={handleHwcClientWarehouseChange}
              />
            </Form.Item>
            <Form.Item
              label="物流渠道"
              name="chqd"
              rules={[{ required: true, message: '请输入物流渠道' }]}
            >
              <Select
                style={{ width: 280 }}
                options={[
                  {
                    label: '自提',
                    value: '自提'
                  }
                ].concat(
                  ckCode
                    ? hwcClientWarehouseList
                        .find((item) => item.code === ckCode)!
                        .channels.map((item) => ({
                          label: item.name,
                          value: item.name
                        }))
                    : []
                )}
              />
            </Form.Item>
          </Space>
        </Row>
        <Row>
          <Space size="large">
            <Form.Item label="订单备注" name="remark">
              <Input placeholder="订单备注" style={{ width: 290 }} />
            </Form.Item>
            <Form.Item label="销售平台" name="platform">
              <Input placeholder="销售平台" style={{ width: 290 }} />
            </Form.Item>
          </Space>
        </Row>
        {chqd === '自提' && (
          <>
            <Divider orientation="left">自提信息</Divider>
            <Space size="large" direction="vertical">
              <Form.Item label="物流单号" name="waybill">
                <Input placeholder="物流单号" style={{ width: 290 }} />
              </Form.Item>
              <Form.Item label="面单链接" name="pda_url">
                <Input placeholder="面单链接" style={{ width: 500 }} />
              </Form.Item>
            </Space>
          </>
        )}
        <Divider orientation="left">收件人信息</Divider>
        <Row>
          <Space size="large">
            <Form.Item
              label="收件人名"
              name="contact"
              rules={[{ required: true, message: '请输入收件人名' }]}
            >
              <Input placeholder="收件人名" style={{ width: 290 }} />
            </Form.Item>
            <Form.Item
              label="收件电话"
              name="mobile"
              rules={[{ required: true, message: '请输入收件电话' }]}
            >
              <Input placeholder="收件电话" style={{ width: 290 }} />
            </Form.Item>
          </Space>
        </Row>
        <Row>
          <Space size="large">
            <Form.Item
              label="收件地址"
              name="address"
              rules={[{ required: true, message: '请输入收件地址' }]}
            >
              <Input placeholder="收件地址" style={{ width: 290 }} />
            </Form.Item>
            <Form.Item
              label="收件国家"
              name="country"
              initialValue={Country.USA}
              rules={[{ required: true, message: '请输入收件国家' }]}
            >
              <Select
                style={{ width: 290 }}
                placeholder="请选择收件国家"
                options={[{ label: COUNTRY_NAMES[Country.USA], value: Country.USA }]}
              />
            </Form.Item>
          </Space>
        </Row>
        <Row>
          <Space size="large">
            <Form.Item
              label="收件城市"
              name="city"
              rules={[{ required: true, message: '请输入收件城市' }]}
            >
              <Input placeholder="收件城市" style={{ width: 290 }} />
            </Form.Item>
            <Form.Item
              label="州(代码)"
              name="state"
              rules={[{ required: true, message: '请输入州(代码)' }]}
            >
              <Input placeholder="州(代码)" style={{ width: 290 }} maxLength={2} />
            </Form.Item>
          </Space>
        </Row>
        <Row>
          <Space size="large">
            <Form.Item
              label="收件邮编"
              name="zip"
              rules={[{ required: true, message: '请输入收件邮编' }]}
            >
              <Input placeholder="收件邮编" style={{ width: 290 }} />
            </Form.Item>
          </Space>
        </Row>
        <Divider orientation="left">商品信息</Divider>
        <Button
          type="primary"
          onClick={addFulfillStockHandler}
          icon={<PlusOutlined />}
          disabled={ckCode === undefined}
        >
          添加明细
        </Button>
        {orderProducts.length > 0 ? (
          <Table<HwcFulFillProduct>
            rowKey={(record) => record.sku}
            columns={[
              ...columns,
              {
                title: '操作',
                key: 'operations',
                render: (_, __, index: number) => (
                  <Button type="link" onClick={() => removeProductItem(index)}>
                    删除
                  </Button>
                )
              }
            ]}
            dataSource={orderProducts}
          />
        ) : (
          <Empty
            style={{ width: '90%', height: '90%' }}
            image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
            imageStyle={{ height: 60 }}
            description={<Typography.Text>还没添加商品，点击添加明细按钮添加商品</Typography.Text>}
          >
            <Button type="primary" onClick={addFulfillStockHandler} disabled={ckCode === undefined}>
              现在添加
            </Button>
          </Empty>
        )}
      </Form>
      <Divider />
      <Space>
        <Button type="primary" onClick={okClickedHandler} loading={loading}>
          创建订单
        </Button>
        <Button
          onClick={() => {
            form.resetFields();
            setOrderProducts([]);
          }}
          disabled={loading}
        >
          清除数据
        </Button>
      </Space>
    </div>
  );
};

export default HwcFulfillOrderCreatePanel;
